<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div>
                  <div style="display: flex; justify-content: space-between">
                    <h6 class="mb-0">DANH SÁCH CHIẾN ĐUA TOP</h6>
                    <router-link :to="'/top-service/create'">
                      <b-button class="btn btn-light font-weight-bold btn-sm"
                      >Tạo mới
                      </b-button>
                    </router-link>
                  </div>
                  <b-form @submit.prevent="search()" >
                    <div class="filter-transaction">
                      <div class="row mb-12">

                        <b-form-group
                            id="input-group-4"
                            label="Nhập tên bxh:"
                            label-for="dp1"
                            class="col-lg-2"
                        >
                          <input
                              type="text"
                              placeholder="Nhập tên bxh..."
                              class="form-control datatable-input"
                              v-model="paramFilter.name"
                          />
                        </b-form-group>

                        <b-form-group
                            id="input-group-4"
                            label="Chọn dịch vụ:"
                            label-for="dp1"
                            class="col-lg-2"
                        >
                          <select
                              class="form-control datatable-input"
                              data-col-index="7"
                              v-model="paramFilter.service"
                          >
                            <option value="">--Chọn dịch vụ--</option>
                            <option v-for="(prod, index) in service_code" :key="index.value" :value="prod.value">{{ prod.text }}</option>
                          </select>
                        </b-form-group>
                        <b-form-group
                            id="input-group-4"
                            label="Từ ngày:"
                            label-for="dp1"
                            class="col-lg-3"
                        >
                          <date-picker
                              id="dp1"
                              name="dp1"
                              format="YYYY-MM-DD "
                              value-type="format"
                              v-model="paramFilter.date_start"
                              type="date"
                              placeholder="Chọn thời gian"
                          ></date-picker>
                        </b-form-group>
                        <b-form-group
                            id="input-group-4"
                            label="Đến ngày:"
                            label-for="dp1"
                            class="col-lg-3"
                        >
                          <date-picker
                              id="dp1"
                              name="dp1"
                              format="YYYY-MM-DD "
                              value-type="format"
                              v-model="paramFilter.date_end"
                              type="date"
                              placeholder="Chọn thời gian"
                          ></date-picker>
                        </b-form-group>
                        <div class="col-lg-2 mb-lg-0 mb-6">
                          <label>.</label><br />
                          <button
                              class="btn btn-primary btn-primary--icon"
                          >
                          <span>
                            <i class="la la-search"></i>
                              <span>Tìm kiếm</span>
                            </span>
                          </button>
                        </div>

                      </div>



                    </div>
                  </b-form>
                </div>

              </template>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fields"
              >
                <template #cell(id)="data">
                  <div>
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template #cell(name)="data">
                  <div >
                    <div>
                      {{data.item.name}}
                    </div>
                  </div>

                </template>
                <template #cell(service)="data">
                  <div  >
                    <div v-for=" (sv_item, index) in convertString(data.item.service)" v-bind:key="index">
                      {{ sv_item }}
                    </div>
                  </div>

                </template>

                <template #cell(status)="data">
                  <div class="label label-inline " :class="getLabelProcess(data.item,true)">
                    {{getLabelProcess(data.item)}}
                  </div>
                </template>

                <template #cell(date_start)="data">

                  <div >
                    {{data.item.date_start}} -
                    {{data.item.date_end}}
                  </div>
                </template>



                <template #cell(action)="data">
                  <div style="display: flex">
                    <router-link
                        :to="'/top-service/analysis/' + data.item.id"
                    >
                    <span class="p-2" title="Thống kê">  <i class="flaticon flaticon2-analytics text-success"></i></span>
                    </router-link>

                    <router-link

                        :to="'/top-service/edit/' + data.item.id"
                    >
                     <span class="p-2" title="Sửa"> <i class="flaticon flaticon2-pen text-info" ></i></span>
                    </router-link>

                      <router-link
                          :to="'/top-service/bot/' + data.item.id"
                      >
                          <span class="p-2" title="Bot"> <i class="flaticon flaticon2-rocket text-info" ></i></span>
                      </router-link>

                      <router-link
                          :to="'/top-service/milestone/' + data.item.id"
                      >
                          <span class="p-2" title="Mốc nhận thưởng"> <i class="flaticon flaticon2-zig-zag-line-sign" ></i></span>
                      </router-link>

                    <div v-if="getLabelProcess(data.item,false,true) == 0" class="cursor-pointer pl-1" title="Xóa"  @click="destroy(data.item.id)">
                      <i  class="flaticon flaticon2-trash text-danger"
                      >
                    </i>
                    </div>

                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.limit"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import CmsRepository from "@/core/repositories/cmsRepository";

const EventRepository = RepositoryFactory.get("event");

export default {
  name: "List.vue",
  mixins: [Common],
  components:{
    DatePicker
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "STT",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "name",
          label: "Tên bảng xếp hạng",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
          thStyle: { 'max-with': 250 + 'px' },
        },
        {
          key: "service",
          label: "Dịch vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_start",
          label: "Thời gian",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },

        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },

        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      clients: [],
      service_code: [],
      paramFilter:{
        phone:'',
        service:'',
        name:'',
        date_start:'',
        date_end:'',
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Chiến dịch đua top", route: "/top-service" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    convertString(string){
      return JSON.parse(string);
    },
    getLabelProcess(item,isClass = false,isStatus=false){
      let txtClass = 'label-light-info';
      let txt = 'Chưa diễn ra';
      let date_end = Date.parse(item.date_end)
      let date_start = Date.parse(item.date_start)
      let now =   Date.now()
      let status =0;
      if ( now >= date_start && now < date_end ){
        txtClass =  'label-light-warning'
        txt =  'Đang diễn ra'
        status =1;
      }
      if ( now >date_end){
        txtClass =  'label-light-success'
        txt =  'Đã kết thúc'
        status =2;
      }



      if (isClass){
        return txtClass;
      }
      if (isStatus){
        return status;
      }else {
        return txt;
      }
    },
    numberFormat(value) {

      if (typeof value != "number" ) {
        return 0;
      }
      var number = parseInt(value);
      if (isNaN(number) || number == null) {
        return 0;
      }
      var formatter = new Intl.NumberFormat("it-IT");
      return formatter.format(number);
    },
    getType(items){
      let array_type=[];
      let that = this;
      let label = '';
      items.forEach((item) => {
        if (!array_type.includes(item.type)){
          array_type.push(item.type)
        }
      })
      array_type.forEach((item)=>{
        that.arrayFilter.forEach((type)=>{
          if (item == type.status){
            label += type.label+ ', ';
          }
        })
      })
      return label.replace(/, +$/, "");
    },
    formatDate(value){
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }

        this.paramFilter.page = this.currentPage;

        let response = await EventRepository.getAll(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let list =  body.data;
          this.items = list.data;
          this.paginate.total = list.total;
          this.paginate.totalPage = list.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getCodes() {
      try {
        this.$bus.$emit("show-loading", true);
        let that = this
        CmsRepository.listService()
            .then((response) => {
              this.$bus.$emit("show-loading", false);
              if (response.data.error_code) {
                alert(response.data.message);
              } else {
                that.service_code = response.data.data;
              }
            })
            .catch(() => {
              this.$bus.$emit("show-loading", false);
              alert("Có lỗi xảy ra");
            });
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    async destroy(id) {
      if (confirm("Bạn có chắc xóa cài đặt?")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await EventRepository.delete(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },

  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getList();
    this.getCodes();
  }
};
</script>

<style >
.mx-datepicker{
  width: 100%;
}
.filter-transaction{
  padding-top: 30px;
}
</style>
